var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    ref: "selector",
    attrs: {
      items: _setup.identityServerItems,
      "search-input": _setup.searchIdentityServer,
      label: "IdentityServer gebruikers",
      "hide-details": "auto",
      "hide-no-data": "",
      loading: _setup.identityServerIsLoading,
      "item-text": "text",
      "item-value": "id",
      placeholder: "Zoek gebruiker in INNAX IdentityServer...",
      "persistent-placeholder": "",
      "return-object": "",
      outlined: "",
      "prepend-inner-icon": "mdi-cloud",
      clearable: "",
      "loader-height": "4",
      "menu-props": _setup.menuProps,
    },
    on: {
      "update:searchInput": function ($event) {
        _setup.searchIdentityServer = $event
      },
      "update:search-input": function ($event) {
        _setup.searchIdentityServer = $event
      },
      click: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "clear", undefined, $event.key, undefined)
        )
          return null
        return _setup.onClear.apply(null, arguments)
      },
    },
    model: {
      value: _setup.identityServerSelectedUser,
      callback: function ($$v) {
        _setup.identityServerSelectedUser = $$v
      },
      expression: "identityServerSelectedUser",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }