var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addModus,
                          expression: "addModus",
                        },
                      ],
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(_setup.IdentityServerSelection, {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.addModus,
                                expression: "addModus",
                              },
                            ],
                            on: {
                              "on-user-selected": _setup.onUserSelectedAdd,
                              clear: _setup.onClear,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addModus,
                          expression: "addModus",
                        },
                      ],
                    },
                    [_c("v-col", [_c("v-divider")], 1)],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: {
                              name: "Volledige naam",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      class: { noBorders: _setup.readOnly },
                                      attrs: {
                                        "hide-details": "auto",
                                        label: "Naam",
                                        placeholder: "volledige naam",
                                        "persistent-placeholder": "",
                                        outlined: !_setup.readOnly,
                                        "error-messages": errors,
                                        readonly: _setup.readOnly,
                                      },
                                      model: {
                                        value: _setup.selectedData.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.name",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Voornaam",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.firstname,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "firstname", $$v)
                              },
                              expression: "selectedData.firstname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Tussenvoegsel",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.middlename,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "middlename", $$v)
                              },
                              expression: "selectedData.middlename",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Achternaam",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.lastname,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "lastname", $$v)
                              },
                              expression: "selectedData.lastname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Referentie",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.reference,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "reference", $$v)
                              },
                              expression: "selectedData.reference",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Omschrijving",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.selectedData,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "selectedData.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: { name: "Email", rules: "email|required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("v-text-field", {
                                      class: { noBorders: _setup.readOnly },
                                      attrs: {
                                        "hide-details": "auto",
                                        label: "Email",
                                        outlined: !_setup.readOnly,
                                        "persistent-placeholder": "",
                                        readonly: _setup.readOnly,
                                        "error-messages": errors,
                                      },
                                      model: {
                                        value: _setup.selectedData.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.email",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Telefoonnummer",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.selectedData,
                                  "phoneNumber",
                                  $$v
                                )
                              },
                              expression: "selectedData.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Afdeling",
                              placeholder: "bedrijf- en/of afdelingsnaam",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.department,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "department", $$v)
                              },
                              expression: "selectedData.department",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            class: { noBorders: _setup.readOnly },
                            attrs: {
                              "hide-details": "auto",
                              label: "Functie",
                              outlined: !_setup.readOnly,
                              "persistent-placeholder": "",
                              readonly: _setup.readOnly,
                            },
                            model: {
                              value: _setup.selectedData.function,
                              callback: function ($$v) {
                                _vm.$set(_setup.selectedData, "function", $$v)
                              },
                              expression: "selectedData.function",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: {
                              name: "Geldig vanaf",
                              rules: "date_between|required",
                              mode: _setup.checkDateValid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(_setup.DateInput, {
                                      attrs: {
                                        label: "Geldig vanaf",
                                        "persistent-placeholder": "",
                                        errors: errors,
                                        readonly: _setup.readOnly,
                                        outlined: "",
                                      },
                                      model: {
                                        value: _setup.selectedData.validFrom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "validFrom",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.validFrom",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(_setup.ValidationProvider, {
                            attrs: {
                              name: "Geldig tot",
                              rules: `date_between|date_after:${_setup.selectedData.validFrom}`,
                              mode: _setup.checkDateValid,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c(_setup.DateInput, {
                                      class: { noBorders: _setup.readOnly },
                                      attrs: {
                                        label: "Geldig tot",
                                        outlined: !_setup.readOnly,
                                        "persistent-placeholder": "",
                                        errors: errors,
                                        readonly: _setup.readOnly,
                                      },
                                      model: {
                                        value: _setup.selectedData.validTill,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _setup.selectedData,
                                            "validTill",
                                            $$v
                                          )
                                        },
                                        expression: "selectedData.validTill",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "mt-5" },
                [
                  _c(
                    "v-container",
                    { staticClass: "boxed", attrs: { fluid: "" } },
                    [
                      _c("header", [_vm._v("Gekoppelde authenticatie")]),
                      _c(
                        "v-row",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.editMode,
                              expression: "editMode",
                            },
                          ],
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(_setup.IdentityServerSelection, {
                                attrs: { "max-height": 140 },
                                on: {
                                  "on-user-selected": _setup.onUserSelectedEdit,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary mt-4",
                                  attrs: {
                                    dark: "",
                                    disabled: _setup.addUserLoginDisabled,
                                  },
                                  on: { click: _setup.onAddUserLogin },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", center: "" } },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(_setup.UserLoginsGrid, {
                        attrs: {
                          "grid-data": _setup.logins,
                          "read-only": !_setup.editMode,
                        },
                        on: { "remove-user-login": _setup.onRemoveUserLogin },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_setup.readOnly
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  disabled: _setup.disableSaveIdentityServer,
                                  label:
                                    "De gebruiker toevoegen aan INNAX IdentityServer",
                                  readonly: _setup.readOnly,
                                  "hide-details": "",
                                },
                                model: {
                                  value: _setup.doSaveToIdentityServer,
                                  callback: function ($$v) {
                                    _setup.doSaveToIdentityServer = $$v
                                  },
                                  expression: "doSaveToIdentityServer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(_setup.ValidationProvider, {
                                attrs: { name: "Gebruikersnaam" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            class: {
                                              noBorders: _setup.readOnly,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Gebruikersnaam",
                                              outlined: !_setup.readOnly,
                                              "persistent-placeholder": "",
                                              readonly: _setup.readOnly,
                                              errors: errors,
                                              "error-messages": errors,
                                              disabled:
                                                _setup.disableSaveIdentityServer ||
                                                !_setup.doSaveToIdentityServer,
                                            },
                                            model: {
                                              value:
                                                _setup.identityServerUserName,
                                              callback: function ($$v) {
                                                _setup.identityServerUserName =
                                                  $$v
                                              },
                                              expression:
                                                "identityServerUserName",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1515209787
                                ),
                              }),
                            ],
                            1
                          ),
                          _setup.identityServerUserNameInvalid
                            ? _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-alert",
                                    { attrs: { outlined: "", type: "error" } },
                                    [_vm._v(" Gebruikersnaam bestaat al ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-container",
                { attrs: { fluid: "", "mt-5": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _setup.readOnly
                        ? _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onEdit()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                  _vm._v(" Wijzigen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onSave()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Opslaan "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_setup.readOnly,
                              expression: "!readOnly",
                            },
                          ],
                          staticClass: "form-group",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }